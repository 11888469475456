import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRoute';

export const PartnerAdministrationPaths = {
  HOME: `/partners`,
};

const PartnerAdministration = (props) => {
  let {
    match: { path },
  } = props;

  return (
    <Switch>
      <PrivateRoute
        path={path}
        exact={true}
        component={lazy(() => import('./Administrator/NameList'))}
      />
    </Switch>
  );
};

export default React.memo(PartnerAdministration);
