import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Layout } from 'antd';

import SideBar from './SideBar';
import TopHeader from './TopHeader';

const AppLayout = ({ children }) => {
  const classes = useStyles();
  const [isSidebarActive, setIsSidebarActive] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarActive(!isSidebarActive);
  };

  return (
    <>
      <Layout hasSider className={classes.appHeader}>
        <TopHeader toggleSidebar={toggleSidebar} />
        <div className={classes.appContainer}>
          <SideBar isActive={isSidebarActive} />
          <Layout className={classes.contentArea}>{children}</Layout>
        </div>
      </Layout>
    </>
  );
};

const useStyles = makeStyles({
  appHeader: {
    display: 'grid',
    gridTemplateColumns: 'auto',
  },
  appContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    background: '#F5F6F8',
  },
  contentArea: {
    height: '93vh',
    flex: 1,
    background: '#F5F6F8',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export default React.memo(AppLayout);
