import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Layout, Menu } from 'antd';
import {
  SnippetsOutlined,
  LogoutOutlined,
  FileAddOutlined,
  FormOutlined,
  BarChartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  BookOutlined,
} from '@ant-design/icons';
import { withRouter, matchPath } from 'react-router-dom';

import { colors, fontSizes, fontWeight, spaces } from '../../Css';
import { useAuthenticatedUser } from '../../hooks/useAuthenticatedUser';
import { AccessibleModules, Modules } from '../../app.config';
import Auth from '../../utils/Auth';
import { PublicPaths } from '../../routes';
import { Api } from '../../utils/Api';

const { Sider } = Layout;

const SideBar = ({ isActive, history, location }) => {
  const classes = useStyles();
  const [sideBarMenuItems, setSideBarMenuItems] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const { role, department } = useAuthenticatedUser();
  const user = Auth.getUser();

  useEffect(() => {
    const menuItems = AccessibleModules({ role, department }).map((module) => ({
      name: module.name,
      icon: getMenuIcon(module.id),
      path: module.sideBarConfigs.path,
      subTabs: module.sideBarConfigs.subNavs,
    }));
    setSideBarMenuItems(menuItems);
  }, [role, department]);

  const getMenuIcon = (moduleId) => {
    switch (moduleId) {
      case Modules.CASE_MANAGEMENT.id:
        return FileAddOutlined;
      case Modules.INFO_MANAGEMENT.id:
        return FormOutlined;
      case Modules.WORKBENCH.id:
        return SnippetsOutlined;
      case Modules.OVERVIEW.id:
        return BarChartOutlined;
      case Modules.TRACE_MANAGEMENT.id:
        return BookOutlined;
      case Modules.USER_ADMINISTRATION.id:
        return UserOutlined;
      case Modules.PARTNER_ADMINISTRATION.id:
        return AppstoreAddOutlined;
      default:
        return null;
    }
  };

  const getMenuKey = (list) => {
    return list.map((item) => item.toLowerCase().replace(/\s/g, '-')).join('/');
  };

  const handleMenuClick = (path) => {
    history.push(path);
  };

  const handleLogout = () => {
    Auth.removeToken();
    Api.defaults.headers.common['Authorization'] = null;
    delete Api.defaults.headers.common['Authorization'];
    history.push(PublicPaths.LOGIN);
  };

  const activeKey = useMemo(() => {
    //Loop through the menu items
    for (let i = 0; i < sideBarMenuItems.length; i++) {
      const menuItem = sideBarMenuItems[i];
      if (menuItem.subTabs) {
        //If it has sub tabs loop through the sub tabs
        for (let j = 0; j < menuItem.subTabs.length; j++) {
          const subMenuItem = menuItem.subTabs[j];

          const match = matchPath(location.pathname, {
            path: subMenuItem.path,
          });

          //If any of the sub tabs match return the key for the menu item and the sub item that matched
          if (Boolean(match)) {
            return [getMenuKey([menuItem.name]), getMenuKey([menuItem.name, subMenuItem.name])];
          }
        }
      } else {
        //If it has no sub tabs check whether it matches
        const match = matchPath(location.pathname, {
          path: menuItem.path,
        });

        //If it matches return key for menu item
        if (Boolean(match)) {
          return [getMenuKey([menuItem.name])];
        }
      }
    }
  }, [location.pathname, sideBarMenuItems]);

  const onOpenChange = (openKey) => {
    const latestOpenKey = openKey.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <Sider
      className={classes.container}
      trigger={null}
      collapsible
      collapsed={!isActive}
      width={240}>
      <Menu
        className={classes.appMenu}
        theme="light"
        mode="inline"
        selectedKeys={activeKey}
        onOpenChange={onOpenChange}
        openKeys={openKeys}>
        {isActive && (
          <div className={classes.appMenuRoleContainer}>
            {user && user.roleUnchanged
              ? user.roleUnchanged.split(' ').map((eachValue, index) => (
                  <span key={index} className={classes.appMenuRole}>
                    {eachValue}
                  </span>
                ))
              : ''}
          </div>
        )}
        {sideBarMenuItems.map((menuItem, i) => {
          return menuItem.subTabs ? (
            <Menu.SubMenu
              key={getMenuKey([menuItem.name])}
              title={menuItem.name}
              icon={<menuItem.icon className={classes.menuIcon} />}>
              {menuItem.subTabs.map((subMenuItem, j) => (
                <Menu.Item
                  key={getMenuKey([menuItem.name, subMenuItem.name])}
                  onClick={() => handleMenuClick(subMenuItem.path)}>
                  {subMenuItem.name}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item
              key={getMenuKey([menuItem.name])}
              icon={<menuItem.icon className={classes.menuIcon} />}
              onClick={() => handleMenuClick(menuItem.path)}>
              {menuItem.name}
            </Menu.Item>
          );
        })}
        <Menu.Item
          className={classes.logoutBtn}
          onClick={handleLogout}
          icon={<LogoutOutlined className={classes.logoutIcon} />}>
          Log Out
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

const useStyles = makeStyles({
  container: {
    background: colors.white,
  },
  appMenuRoleContainer: {
    backgroundColor: 'inherit',
    borderRight: 'none',
    height: '58px !important',
    marginBottom: '-2px !important',
    display: 'flex',
    fontWeight: 600,
    paddingLeft: 20,
  },
  appMenuRole: {
    color: 'black',
    paddingTop: 30,
    textTransform: 'lowercase',
    marginLeft: '5px',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  appMenu: {
    border: 'none',
    '& .ant-menu-item': {
      height: 57,
      alignItems: 'center',
      marginTop: '-0.45px',
      paddingTop: '9px',
    },
    '& .ant-menu-item::after': {
      left: 0,
      borderRight: `0px solid transparent !important`,
    },
    '& .ant-menu-item-selected': {
      borderLeft: `4px solid ${colors.primary}`,
    },
    '& .ant-menu-item-selected.ant-menu-item-selected': {
      backgroundColor: 'rgba(19,135,80,0.08)',
      color: colors.black,
      fontWeight: fontWeight.medium,
    },
    '& .ant-menu-sub': {
      backgroundColor: colors.lightIndigo,

      '& .ant-menu-item-selected': {
        borderLeft: 'none',
      },
    },
    '& .ant-menu-submenu-selected': {
      backgroundColor: 'rgba(19,135,80,0.08)',
      marginTop: '-10px !important',
    },
    '& .ant-menu-submenu-title': {
      margin: 0,
    },
    '& .ant-menu-submenu-selected .ant-menu-submenu-title::before': {
      borderLeft: `4px solid ${colors.primary}`,
      content: `''`,
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
    },
    '& .ant-menu-submenu .ant-menu-item': {
      background: 'transparent',
    },
    '& .ant-menu-submenu .ant-menu-item::after': {
      border: 'none',
    },
    '& .ant-menu-submenu div.ant-menu-submenu-title': {
      height: 50,
      lineHeight: '50px',
    },
  },
  menuIcon: {
    fontSize: `${fontSizes.xxlarge}px !important`,
    color: colors.secondaryBase,
  },
  logoutBtn: {
    position: 'absolute !important',
    bottom: 40,
    left: 0,
    right: 0,
    padding: spaces.medium,
    fontSize: fontSizes.medium,
    top: '86vh',
  },
  logoutIcon: {
    fontSize: `${fontSizes.xxlarge}px !important`,
  },
});

export default React.memo(withRouter(SideBar));
