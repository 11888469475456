import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRoute';

export const UserAdministrationPaths = {
  HOME: `/users`,
};

const UserAdministration = (props) => {
  let {
    match: { path },
  } = props;

  return (
    <Switch>
      <PrivateRoute
        path={path}
        component={lazy(() => import('./Administrator/AdminList'))}
        exact={true}
      />
    </Switch>
  );
};

export default React.memo(UserAdministration);
