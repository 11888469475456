import React, { useEffect } from 'react';
import { Form, Button, Input, Row } from 'antd';
import { makeStyles } from '@material-ui/styles';
import { notification } from 'antd';
import { useMutation } from 'react-query';

import history from '../../../routes/history';
import { fontWeight, spaces, colors } from '../../../Css';
import AuthenticationLayout from '../components/AuthenticationLayout';
import resetPasswordPageImage from '../../../assets/images/create-password.svg';
import Services from '../services';

const ResetPassword = () => {
  const [form] = Form.useForm();

  const classes = useStyles();

  const [resetPassword, { isSuccess, isError, error, isLoading }] = useMutation(
    Services.resetPassword,
  );

  useEffect(() => {
    if (isError) {
      notification['error']({
        message: error.message ? error.message : 'Failed to reset password',
      });
    }

    if (isSuccess) {
      notification['success']({
        message: 'Password reset was successful, please login',
      });
      history.push('/login');
    }
  }, [isSuccess, isError, error]);

  const handleFinish = async (values) => {
    const token = new URLSearchParams(window.location.search).get('token');
    await resetPassword({
      token,
      password: values.password,
    });
  };

  const compareWithPassword = (_rule, _value, callback) => {
    if (form.getFieldsValue().password !== form.getFieldsValue().confirmPassword) {
      callback('password do not match');
    } else {
      callback();
    }
  };

  return (
    <AuthenticationLayout
      title="Password Reset"
      subTitle="Enter and confirm your new password"
      formTitle="Enter your details"
      imgSrc={resetPasswordPageImage}>
      <Form form={form} onFinish={handleFinish} layout="vertical" hideRequiredMark={true}>
        <Form.Item
          label="Enter new password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input type="password" placeholder="Enter password" autoFocus className={classes.input} />
        </Form.Item>
        <Form.Item
          label="Confirm new password"
          name="confirmPassword"
          rules={[
            { required: true, message: 'Please input confirm password!' },
            { validator: compareWithPassword },
          ]}>
          <Input
            type="password"
            placeholder="Enter confirm password"
            autoFocus
            className={classes.input}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%', marginBottom: 20 }}>
            {isLoading ? 'Loading ...' : 'Reset Password'}
          </Button>
        </Form.Item>
        <Row justify="center">
          <Button type="link" onClick={() => history.push('/login')} className={classes.link}>
            Remember password?
          </Button>
        </Row>
      </Form>
    </AuthenticationLayout>
  );
};

const useStyles = makeStyles({
  button: {
    marginRight: spaces.medium,
    marginBottom: spaces.small,
    fontWeight: fontWeight.bold,
  },

  link: {
    color: colors.info,
  },
  input: {
    height: 43,
  },
});

export default ResetPassword;
