import React, { useEffect } from 'react';
import { Form, Button, Input, Row } from 'antd';
import { makeStyles } from '@material-ui/styles';
import { notification } from 'antd';
import { useMutation } from 'react-query';

import history from '../../../routes/history';
import { fontWeight, spaces, colors } from '../../../Css';
import AuthenticationLayout from '../components/AuthenticationLayout';
import forgotPasswordPageImage from '../../../assets/images/reset-password.svg';
import Services from '../services';

const ForgotPassword = () => {
  const [form] = Form.useForm();

  const classes = useStyles();

  const [forgotPassword, { error, isLoading, isError, isSuccess }] = useMutation(
    Services.forgotPassword,
  );

  useEffect(() => {
    if (isError) {
      notification['error']({
        message: error.message
          ? error.message
          : 'Failed to send link to reset your password, please try again',
      });
    }

    if (isSuccess) {
      notification['success']({
        message: 'An email has been sent to you to reset your password',
      });
      form.resetFields();
    }
  }, [isSuccess, isError, error, form]);

  const handleFinish = async (values) => {
    await forgotPassword({
      email: values.email,
    });
  };

  return (
    <AuthenticationLayout
      title="Forgot password"
      subTitle="Provide the email you used in registering your account"
      formTitle="Enter your email"
      imgSrc={forgotPasswordPageImage}>
      <Form form={form} onFinish={handleFinish} layout="vertical" hideRequiredMark={true}>
        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}>
          <Input
            type="email"
            placeholder="Enter your email address"
            autoFocus
            className={classes.input}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%', marginBottom: 20 }}>
            {isLoading ? 'Loading ...' : 'Send link'}
          </Button>
        </Form.Item>
        <Row justify="center">
          <Button type="link" onClick={() => history.push('/login')} className={classes.link}>
            Remember password?
          </Button>
        </Row>
      </Form>
    </AuthenticationLayout>
  );
};

const useStyles = makeStyles({
  button: {
    marginRight: spaces.medium,
    marginBottom: spaces.small,
    fontWeight: fontWeight.bold,
  },

  link: {
    color: colors.info,
  },
  input: {
    height: 43,
  },
});

export default ForgotPassword;
