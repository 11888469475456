import React, { useEffect, useState } from 'react';
import { Form, Button, Input, Row, Checkbox } from 'antd';
import { makeStyles } from '@material-ui/styles';
import { notification } from 'antd';
import { useMutation } from 'react-query';

import history from '../../../routes/history';
import { fontWeight, spaces, colors } from '../../../Css';
import AuthenticationLayout from '../components/AuthenticationLayout';
import createPasswordPageImage from '../../../assets/images/create-password.svg';
import Services from '../services';

const CreatePassword = () => {
  const [form] = Form.useForm();

  const [checked, setChecked] = useState(false);

  const classes = useStyles();

  const [createPassword, { isSuccess, isError, error, isLoading }] = useMutation(
    Services.resetPassword,
  );

  useEffect(() => {
    if (isError) {
      notification['error']({
        message: error.message ? error.message : 'Failed to create new password',
      });
    }

    if (isSuccess) {
      notification['success']({
        message: 'Password was created successful, please login',
      });
      history.push('/login');
    }
  }, [isSuccess, isError, error]);

  const handleFinish = async (values) => {
    if (checked) {
      const token = new URLSearchParams(window.location.search).get('token');
      await createPassword({
        token,
        password: values.password,
      });
    } else {
      notification['error']({
        message: 'Please accept the terms and conditions',
      });
    }
  };

  const compareWithPassword = (_rule, _value, callback) => {
    if (form.getFieldsValue().password !== form.getFieldsValue().confirmPassword) {
      callback('password do not match');
    } else {
      callback();
    }
  };

  return (
    <AuthenticationLayout
      title="Create new password"
      subTitle="Enter your new password to access your account"
      formTitle="Enter your details"
      imgSrc={createPasswordPageImage}
      smallTextTitle>
      <Form form={form} onFinish={handleFinish} layout="vertical" hideRequiredMark={true}>
        <Form.Item
          label="Enter new password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input type="password" placeholder="Enter password" autoFocus className={classes.input} />
        </Form.Item>
        <Form.Item
          label="Confirm new password"
          name="confirmPassword"
          rules={[
            { required: true, message: 'Please input confirm password!' },
            { validator: compareWithPassword },
          ]}>
          <Input
            type="password"
            placeholder="Enter confirm password"
            autoFocus
            className={classes.input}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox onChange={(e) => setChecked(e.target.checked)} />
          <span className={classes.terms}>I accept the terms and conditions</span>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%', marginBottom: 20 }}>
            {isLoading ? 'Loading ...' : 'Sign in'}
          </Button>
        </Form.Item>
        <Row justify="center">
          <Button
            type="link"
            onClick={() => history.push('/forgot-password')}
            className={classes.link}>
            Forgot your password?
          </Button>
        </Row>
      </Form>
    </AuthenticationLayout>
  );
};

const useStyles = makeStyles({
  button: {
    marginRight: spaces.medium,
    marginBottom: spaces.small,
    fontWeight: fontWeight.bold,
  },
  link: {
    color: colors.info,
  },
  terms: {
    marginLeft: spaces.small,
  },
  title: {
    fontSize: 10,
  },
  input: {
    height: 43,
  },
});

export default CreatePassword;
