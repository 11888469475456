import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { Departments } from '../../../reusables/AccessControl';

export const TraceManagementPaths = {
  HOME: '/trace-management',
  OVERVIEW: '/trace-management/overview',
};

const StateCoordinatorRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={`${TraceManagementPaths.OVERVIEW}`}
        component={lazy(() => import('../../overview/pages/ContactTrace'))}
      />
      <PrivateRoute
        path={TraceManagementPaths.HOME}
        exact={true}
        component={lazy(() => import('./StateCoordinator/ContactList'))}
      />
      <PrivateRoute
        path={`${TraceManagementPaths.HOME}/:id`}
        exact={true}
        component={lazy(() => import('./StateCoordinator/ContactListDetails'))}
      />
    </Switch>
  );
};

const ContactTraceRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={TraceManagementPaths.HOME}
        exact={true}
        component={lazy(() => import('./ContactTrace/UserList'))}
      />
      <PrivateRoute
        path={`${TraceManagementPaths.HOME}/:id`}
        exact={true}
        component={lazy(() => import('./ContactTrace/UserListDetails'))}
      />
    </Switch>
  );
};

const TraceManagementRoutes = () => {
  const { department } = useAuthenticatedUser();

  switch (department) {
    case Departments.STATE_COORDINATOR:
      return <StateCoordinatorRoutes />;
    case Departments.CONTACT_TRACE:
      return <ContactTraceRoutes />;
    default:
      return null;
  }
};

export default React.memo(TraceManagementRoutes);
