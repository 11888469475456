// Auth handles authentication on the app
// This may inlude getting and setting tokens, removing tokens etc

export default class Auth {
  static isAuthenticated() {
    const token = localStorage.getItem('token');
    return token ? true : false;
  }

  static setToken(token) {
    localStorage.setItem('token', JSON.stringify(token));
  }

  static getToken() {
    let token = localStorage.getItem('token');
    return !!token === true ? JSON.parse(token) : null;
  }

  //@todo setUser might not be necessary especially since we will have a
  // /me endpoint
  static setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  //@todo getUser might not be necessary especially since we will have a
  // /me endpoint
  static getUser() {
    let user = localStorage.getItem('user');
    return !!user === true ? JSON.parse(user) : null;
  }

  static removeToken() {
    localStorage.removeItem('token');
  }

  static setStatuses(statuses) {
    localStorage.setItem('statuses', JSON.stringify(statuses));
  }

  static getStatuses() {
    let statuses = localStorage.getItem('statuses');

    return !!statuses === true ? JSON.parse(statuses) : null;
  }

  static setMyLgas(lgas) {
    localStorage.setItem('myLgas', JSON.stringify(lgas));
  }

  static getMyLgas() {
    let lgas = localStorage.getItem('myLgas');

    return !!lgas === true ? JSON.parse(lgas) : null;
  }
}
