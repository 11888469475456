/**
 * This hook makes a request to the backend to get the user details, if the details isn't already in the cache and transorms it to match the role format defined in the Roles object.
 * A role, department, user and isSucess can be destructed from this hook.
 **/
import { Roles, Departments } from '../reusables/AccessControl';
import Auth from '../utils/Auth';

const useUserDetails = () => {
  //@todo: Add react-query here that gets the current user and return
  //the data as user and its isSuccess and isError state
  const user = Auth.getUser();
  return {
    user,
    isSuccess: true,
    isError: false,
    isLoading: false,
  };
};

export const useAuthenticatedUser = () => {
  const { user, ...rest } = useUserDetails();
  const department = user.department.toUpperCase().replace(/\s/g, '_');
  const role = user.role.toUpperCase();
  const roleUnchanged = user.roleUnchanged;

  return {
    role: Roles[role],
    roleUnchanged,
    department: Departments[department],
    user,
    ...rest,
  };
};
