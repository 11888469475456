import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { Departments, Roles } from '../../../reusables/AccessControl';
import RoleSpecificLoader from '../../../reusables/RoleSpecificLoader';

export const WorkbenchPaths = {
  HOME: '/workbench',
  ASSESSMENT: `/workbench/assessment`,
  COMMUNITY: `/workbench/community`,
  CALL_LOG: `/workbench/call-log`,
  SOCIAL_MEDIA: `/workbench/social-media`,
  OVERVIEW: '/workbench/overview',
};

const CaseInvestigationRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={WorkbenchPaths.HOME}
        exact={true}
        component={lazy(() => import('./CaseInvestigation'))}
      />
      <PrivateRoute
        path={`${WorkbenchPaths.HOME}/:id`}
        exact={true}
        component={RoleSpecificLoader({
          [Roles.SUPERVISOR]: lazy(() =>
            import('./CaseInvestigation/AssigneeListDetails.supervisor'),
          ),
        })}
      />
      <PrivateRoute
        path={`${WorkbenchPaths.HOME}/:id/form`}
        exact={true}
        component={RoleSpecificLoader({
          [Roles.OFFICER]: lazy(() => import('./CaseInvestigation/CaseListForm.officer')),
          [Roles.SUPERVISOR]: lazy(() => import('./CaseInvestigation/CaseListForm.officer')),
        })}
      />
    </Switch>
  );
};

const CallLoggerRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={WorkbenchPaths.HOME}
        exact={true}
        component={lazy(() => import('./CallCenterLogger'))}
      />
      <PrivateRoute
        path={`${WorkbenchPaths.HOME}/select-category`}
        exact={true}
        component={lazy(() => import('./CallCenterLogger'))}
      />
      <PrivateRoute
        path={`${WorkbenchPaths.HOME}/assessment-flow`}
        exact={true}
        component={lazy(() => import('./CallCenterLogger'))}
      />
      <PrivateRoute
        path={`${WorkbenchPaths.HOME}/report-flow`}
        exact={true}
        component={lazy(() => import('./CallCenterLogger'))}
      />
    </Switch>
  );
};

const LabRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={WorkbenchPaths.HOME}
        exact={true}
        component={lazy(() => import('./Lab/CaseList'))}
      />
      <PrivateRoute
        path={`${WorkbenchPaths.HOME}/:id/form`}
        exact={true}
        component={lazy(() => import('./Lab/CaseListForm'))}
      />
    </Switch>
  );
};

const ContactTraceRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={WorkbenchPaths.HOME}
        exact={true}
        component={lazy(() => import('./ContactTrace/AssigneeList'))}
      />
      <PrivateRoute
        path={`${WorkbenchPaths.HOME}/:id`}
        exact={true}
        component={lazy(() => import('./ContactTrace/AssigneeListDetails'))}
      />
    </Switch>
  );
};

const StateCoordinatorRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={WorkbenchPaths.ASSESSMENT}
        exact={true}
        component={lazy(() => import('./StateCoordinator/AssessmentReport'))}
      />
      <PrivateRoute
        path={WorkbenchPaths.COMMUNITY}
        exact={true}
        component={lazy(() => import('./StateCoordinator/CommunityReport'))}
      />
      <PrivateRoute
        path={WorkbenchPaths.CALL_LOG}
        exact={true}
        component={lazy(() => import('./StateCoordinator/CallLog'))}
      />
      <PrivateRoute
        path={WorkbenchPaths.SOCIAL_MEDIA}
        exact={true}
        component={lazy(() => import('./StateCoordinator/SocialMedia'))}
      />
      <PrivateRoute
        path={WorkbenchPaths.OVERVIEW}
        component={lazy(() => import('../../overview/pages/StateCoordinator/Surveillance'))}
      />
    </Switch>
  );
};

const SurveillanceRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={WorkbenchPaths.ASSESSMENT}
        exact={true}
        component={lazy(() => import('./Surveillance/AssessmentReport'))}
      />
      <PrivateRoute
        path={`${WorkbenchPaths.ASSESSMENT}/:id`}
        exact={true}
        component={RoleSpecificLoader({
          [Roles.OFFICER]: lazy(() => import('./Surveillance/AssessmentReportForm.officer')),
        })}
      />
      <PrivateRoute
        path={WorkbenchPaths.COMMUNITY}
        exact={true}
        component={lazy(() => import('./Surveillance/CommunityReport'))}
      />
      <PrivateRoute
        path={WorkbenchPaths.CALL_LOG}
        exact={true}
        component={lazy(() => import('./Surveillance/CallLog'))}
      />
      <PrivateRoute
        path={WorkbenchPaths.SOCIAL_MEDIA}
        exact={true}
        component={lazy(() => import('./Surveillance/SocialMedia'))}
      />
    </Switch>
  );
};

const WorkbenchRoutes = () => {
  const { department } = useAuthenticatedUser();

  switch (department) {
    case Departments.SURVEILLANCE:
      return <SurveillanceRoutes />;
    case Departments.CASE_INVESTIGATION:
      return <CaseInvestigationRoutes />;
    case Departments.CALL_CENTER_LOGGER:
      return <CallLoggerRoutes />;
    case Departments.LAB:
      return <LabRoutes />;
    case Departments.CONTACT_TRACE:
      return <ContactTraceRoutes />;
    case Departments.STATE_COORDINATOR:
      return <StateCoordinatorRoutes />;
    default:
      return null;
  }
};

export default React.memo(WorkbenchRoutes);
