import { makeStyles } from '@material-ui/styles';

// Common utils such as colors, fontSizes, fontWeight and common styles falls here
// Common styles could be boxShadow style or any globally used styles.
// NB: Use the common styles i.e useStyles sparingly and only add styles when you are absolutely sure of it
export const colors = {
  primary: '#138750',
  primaryWeak: '#ECF5F0',
  secondary: '#011429',
  imageBackground: '#FAFAFA',
  white: '#fff',
  base: '#212B36',
  secondaryBase: '#637381',
  success: '#50B83C',
  successWeak: '#BBE5B3',
  successWeakText: '#414F3E',
  error: '#DE3618',
  errorWeak: '#FFC48B',
  errorWeakText: '#594430',
  info: '#0070E0',
  infoWeak: '#B5E0FB',
  warning: '#F49342',
  warningWeak: '#FFEA8A',
  warningWeakText: '#595130',
  darkGrey: '#919EAB',
  lightGrey: '#F5F6F8',
  seperator: '#DFE3E8',
  teal: '#47C1BF',
  tealLighter: '#E0F5F5',
  tealLight: '#B7ECEC',
  grey: '#979797',
  grayishBlue: '#454F5B',
  skyLight: '#F4F6F8',
  skyLighter: '#F9FAFB',
  ashGrey: '#D8D8D8',
  lightBlue: '#B4E1FA',
  black: '#001429',
  lightIndigo: '#F4F5FA',
  cyanBlueDark: '#445571',
  blueLighter: '#EBF5FA',
};

// #3f3f4426

export const fontSizes = {
  xxsmall: 8,
  xsmall: 10,
  small: 12,
  medium: 14,
  large: 16,
  xlarge: 18,
  xxlarge: 20,
  title: 28,
};

export const fontWeight = {
  bold: 700,
  medium: 600,
  regular: 400,
  light: 300,
};

export const boxShadow = {
  card: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,.15)',
};

export const spaces = {
  small: 10,
  medium: 20,
  large: 30,
};

export const useStyles = makeStyles({
  filterMenuList: {
    '&.ant-menu': {
      border: 0,
    },
    '& .ant-menu-item': {
      padding: 0,
      border: 0,
      lineHeight: 2.0,
      height: 'fit-content',
      margin: '0 !important',
    },
    '& .ant-menu-item span:last-child': {
      paddingRight: 0,
    },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
});
