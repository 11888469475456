import React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';

import DashboardRoutes from './DashboardRoutes';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import history from './history';
import Login from '../modules/authentication/pages/Login';
import ForgotPassword from '../modules/authentication/pages/ForgotPassword';
import ResetPassword from '../modules/authentication/pages/ResetPassword';
import CreatePassword from '../modules/authentication/pages/CreatePassword';

export const PublicPaths = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  CREATE_PASSWORD: '/verify-user',
};

const publicRoutes = [
  { path: PublicPaths.LOGIN, exact: true, component: Login },
  { path: PublicPaths.RESET_PASSWORD, exact: true, component: ResetPassword },
  { path: PublicPaths.FORGOT_PASSWORD, exact: true, component: ForgotPassword },
  { path: PublicPaths.CREATE_PASSWORD, exact: true, component: CreatePassword },
];

// Routes contains renders all public routes i.e login, signup, forgotPassword views.
// It also renders the DashboardRoutes component which will be responsible for lazy loading
// dashboard views based on the user's department and accessible modules sent by the server
const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        {publicRoutes.map((route, index) => (
          <PublicRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
        <PrivateRoute path={'/'} exact={false} component={DashboardRoutes} />
        <Redirect to={PublicPaths.LOGIN} />
      </Switch>
    </Router>
  );
};

export default React.memo(Routes);
