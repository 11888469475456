import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Spin } from 'antd';

import PrivateRoute from './PrivateRoute';
import AppLayout from '../components/AppLayout';
import { AccessibleModules, Modules, getDefaultPath } from '../app.config';
import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';
import { OverviewPaths } from '../modules/overview/pages';
import { WorkbenchPaths } from '../modules/workbench/pages';
import { CaseManagementPaths } from '../modules/caseManagement/pages';
import { InfoManagementPaths } from '../modules/infoManagement/pages';
import { TraceManagementPaths } from '../modules/traceManagement/pages';
import { UserAdministrationPaths } from '../modules/userAdministration/pages';
import { PartnerAdministrationPaths } from '../modules/partnerAdministration/pages';

// DashboardRoutes renders all private routes.
// The logic is dependent on the accessible modules and the user's department
// which are both evaluated at runtime
const DashboardRoutes = () => {
  const [privateRoutes, setPrivateRoutes] = useState([]);
  const { role, department, isSuccess, roleUnchanged } = useAuthenticatedUser();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    if (
      roleUnchanged &&
      (roleUnchanged === 'CONTACT TRACING OFFICER' || roleUnchanged === 'COMMUNITY INFORMANT')
    ) {
      setPrivateRoutes([
        {
          path: '/mobile',
          exact: true,
          component: lazy(() => import('./Mobile')),
        },
      ]);
      return;
    }

    if (role || department) {
      const userRoutes = AccessibleModules({ role, department }).map((module) =>
        getRoute(module.id),
      );
      setPrivateRoutes(userRoutes);

      if (location.pathname === '/') {
        const defaultPath = getDefaultPath({ department, role });
        history.push(defaultPath);
      }
    }
    // eslint-disable-next-line
  }, [role, department, location]);

  const getRoute = (moduleId) => {
    switch (moduleId) {
      case Modules.WORKBENCH.id:
        return {
          path: WorkbenchPaths.HOME,
          exact: false,
          component: lazy(() => import('../modules/workbench/pages')),
        };
      case Modules.CASE_MANAGEMENT.id:
        return {
          path: CaseManagementPaths.HOME,
          exact: false,
          component: lazy(() => import('../modules/caseManagement/pages')),
        };
      case Modules.INFO_MANAGEMENT.id:
        return {
          path: InfoManagementPaths.HOME,
          exact: false,
          component: lazy(() => import('../modules/infoManagement/pages')),
        };
      case Modules.TRACE_MANAGEMENT.id:
        return {
          path: TraceManagementPaths.HOME,
          exact: false,
          component: lazy(() => import('../modules/traceManagement/pages')),
        };
      case Modules.USER_ADMINISTRATION.id:
        return {
          path: UserAdministrationPaths.HOME,
          exact: false,
          component: lazy(() => import('../modules/userAdministration/pages')),
        };
      case Modules.PARTNER_ADMINISTRATION.id:
        return {
          path: PartnerAdministrationPaths.HOME,
          exact: false,
          component: lazy(() => import('../modules/partnerAdministration/pages')),
        };
      case Modules.OVERVIEW.id:
        return {
          path: OverviewPaths.HOME,
          exact: false,
          component: lazy(() => import('../modules/overview/pages')),
        };
      default:
        return null;
    }
  };

  return (
    <AppLayout>
      {isSuccess && (
        <Suspense
          fallback={
            <span className={classes.suspense}>
              <Spin />
            </span>
          }>
          <Switch>
            {privateRoutes.map((route, index) => (
              <PrivateRoute
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
          </Switch>
        </Suspense>
      )}
    </AppLayout>
  );
};

const useStyles = makeStyles({
  suspense: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
});

export default React.memo(DashboardRoutes);
