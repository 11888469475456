import PropTypes from 'prop-types';

import { useAuthenticatedUser } from '../hooks/useAuthenticatedUser';

export const Departments = {
  SURVEILLANCE: `surveillance`,
  CASE_INVESTIGATION: `caseInvestigation`,
  CALL_CENTER_LOGGER: `call-center-logger`,
  LAB: `lab`,
  STATE_COORDINATOR: `state_coordinator`,
  CONTACT_TRACE: `contact_trace`,
  ADMINISTRATOR: `administrator`,
};

export const Roles = {
  SUPERVISOR: `supervisor`,
  OFFICER: `officer`,
};

/**
 * AccessControl either renders or hide its child based on the allowedRole prop
 * If the child is a function i.e render props, it renders the child either ways but passes an [allowed] boolean
 * to the child function
 * @see https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/.
 * Very similar api as the tutorial above. We only just manage our roles using a custom hook
 */
const AccessControl = (props) => {
  const { allowedRole, children } = props;
  const { role } = useAuthenticatedUser();
  const allowed = role === allowedRole;

  if (typeof children === 'function') {
    return children(allowed);
  } else if (allowed) {
    return children;
  }
  return null;
};

AccessControl.propTypes = {
  allowedRole: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default AccessControl;
