import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { Departments } from '../../../reusables/AccessControl';

export const CaseManagementPaths = {
  HOME: '/case-management',
  FORM: '/case-management/form',
  CASE_LIST: '/case-management/case-list',
  OVERVIEW: '/case-management/overview',
};

const CaseInvestigationRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={CaseManagementPaths.HOME}
        exact={true}
        component={lazy(() => import('./CaseInvestigation/CaseList'))}
      />
      <PrivateRoute
        path={[CaseManagementPaths.FORM, `${CaseManagementPaths.FORM}`]}
        exact={true}
        component={lazy(() => import('./CaseInvestigation/CaseListCreateForm'))}
      />
      <PrivateRoute
        path={[CaseManagementPaths.FORM, `${CaseManagementPaths.FORM}/:id`]}
        exact={true}
        component={lazy(() => import('./CaseInvestigation/CaseListForm'))}
      />
      <PrivateRoute
        path={`${CaseManagementPaths.HOME}/:id`}
        exact={true}
        component={lazy(() => import('./CaseInvestigation/CaseListDetails'))}
      />
    </Switch>
  );
};

const LabRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={CaseManagementPaths.HOME}
        exact={true}
        component={lazy(() => import('./Lab/CaseList'))}
      />
    </Switch>
  );
};

const StateCoordinatorRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={CaseManagementPaths.CASE_LIST}
        exact={true}
        component={lazy(() => import('./StateCoordinator/CaseList'))}
      />
      <PrivateRoute
        path={`${CaseManagementPaths.CASE_LIST}/:id`}
        exact={true}
        component={lazy(() => import('./StateCoordinator/CaseListDetails'))}
      />
      <PrivateRoute
        path={CaseManagementPaths.OVERVIEW}
        component={lazy(() => import('../../overview/pages/StateCoordinator/CaseManagement'))}
      />
    </Switch>
  );
};

const CaseManagementRoutes = () => {
  const { department } = useAuthenticatedUser();

  switch (department) {
    case Departments.CASE_INVESTIGATION:
      return <CaseInvestigationRoutes />;
    case Departments.LAB:
      return <LabRoutes />;
    case Departments.STATE_COORDINATOR:
      return <StateCoordinatorRoutes />;
    default:
      return null;
  }
};

export default React.memo(CaseManagementRoutes);
