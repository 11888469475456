import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { Departments } from '../../../reusables/AccessControl';

export const OverviewPaths = {
  HOME: '/overview',
};

const SurveillanceRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={OverviewPaths.HOME}
        exact={false}
        component={lazy(() => import('./Surveillance'))}
      />
      <PrivateRoute
        path={`${OverviewPaths.HOME}/test-api`}
        exact={true}
        component={lazy(() => import('./IntegrationExample'))}
      />
    </Switch>
  );
};

const CallCenterLoggerRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={OverviewPaths.HOME}
        exact={false}
        component={lazy(() => import('./CallCenterLogger'))}
      />
    </Switch>
  );
};

const CaseInvestigationRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={OverviewPaths.HOME}
        exact={false}
        component={lazy(() => import('./CaseInvestigation'))}
      />
    </Switch>
  );
};

const ContactTraceRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={OverviewPaths.HOME}
        exact={false}
        component={lazy(() => import('./ContactTrace'))}
      />
    </Switch>
  );
};

const OverviewRoutes = () => {
  const { department } = useAuthenticatedUser();

  switch (department) {
    case Departments.SURVEILLANCE:
      return <SurveillanceRoutes />;
    case Departments.CALL_CENTER_LOGGER:
      return <CallCenterLoggerRoutes />;
    case Departments.CASE_INVESTIGATION:
      return <CaseInvestigationRoutes />;
    case Departments.CONTACT_TRACE:
      return <ContactTraceRoutes />;
    default:
      return null;
  }
};

export default React.memo(OverviewRoutes);
