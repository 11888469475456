// Services contains all api calls made from this module
// Api calls made to an external service should also be added here
import { Api } from '../../utils/Api';

const Services = {
  login: async function (userdata) {
    try {
      const response = await Api.post('auth/login/', userdata);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response && error.response.data && error.response.data.detail
          ? error.response.data.detail
          : error.response && error.response.statusText
          ? error.response.statusText
          : 'Network issue',
      );
    }
  },
  resetPassword: async function (userdata) {
    try {
      const response = await Api.post('auth/users/create-password/', userdata);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response.data.message ? error.response.data.message : error.response.statusText,
      );
    }
  },
  forgotPassword: async function (userdata) {
    try {
      const response = await Api.post('auth/users/reset-password/', userdata);
      return response.data;
    } catch (error) {
      throw new Error(
        error.response.data.message ? error.response.data.message : error.response.statusText,
      );
    }
  },
};

export default Services;
