import React, { useEffect } from 'react';
import { Form, Button, Input, Row } from 'antd';
import { makeStyles } from '@material-ui/styles';
import { notification } from 'antd';
import { useMutation } from 'react-query';

import history from '../../../routes/history';
import { fontWeight, spaces, colors } from '../../../Css';
import Auth from '../../../utils/Auth';
import AuthenticationLayout from '../components/AuthenticationLayout';
import loginPageImage from '../../../assets/images/login-update.svg';
import Services from '../services';
import { Api } from '../../../utils/Api';

const Login = () => {
  const [form] = Form.useForm();

  const classes = useStyles();

  const [login, { data, status, error, isLoading }] = useMutation(Services.login);

  const getUserDepartment = (role) => {
    let department;
    switch (role) {
      case 'CASE SUPERVISOR':
        department = 'case investigation';
        break;
      case 'CASE OFFICER':
        department = 'case investigation';
        break;
      case 'SURVEILLANCE SUPERVISOR':
        department = 'surveillance';
        break;
      case 'SURVEILLANCE OFFICER':
        department = 'surveillance';
        break;
      case 'CONTACT TRACING SUPERVISOR':
        department = 'contact trace';
        break;
      case 'CONTACT TRACING OFFICER':
        department = 'undefined';
        break;
      case 'STATE COORDINATOR':
        department = 'state coordinator';
        break;
      case 'LABORATORY OFFICER':
        department = 'lab';
        break;
      case 'CALL CENTRE AGENT':
        department = 'call center logger';
        break;
      case 'ADMINISTRATOR':
        department = 'administrator';
        break;
      default:
        department = 'undefined';
        break;
    }

    return department;
  };

  useEffect(() => {
    if (status === 'error') {
      notification['error']({
        message:
          error && error.message
            ? error.message === 'No active account found with the given credentials'
              ? 'Invalid username/password'
              : error.message
            : 'Failed to login',
      });
    }

    if (status === 'success') {
      const role =
        data && data.user.role.trim() && data.user.role.split(' ')[1]
          ? data.user.role.split(' ')[1]
          : data.user.role;

      Auth.setToken(data.access);
      Auth.setUser({
        ...data.user,
        role,
        roleUnchanged: data.user.role,
        department: getUserDepartment(data.user.role),
      });

      Api.defaults.headers.common['Authorization'] = `Bearer ${Auth.getToken()}`;

      if (
        data.user.role === 'CONTACT TRACING OFFICER' ||
        data.user.role === 'COMMUNITY INFORMANT'
      ) {
        history.replace('/mobile');
      } else {
        history.replace('/');
      }
    }
  }, [data, status, error]);

  const handleFinish = async (values) => {
    await login({
      email: values.email,
      password: values.password,
    });
  };

  return (
    <AuthenticationLayout
      title="Welcome back"
      subTitle="Login to continue"
      formTitle="Login"
      imgSrc={loginPageImage}>
      <Form form={form} onFinish={handleFinish} layout="vertical" hideRequiredMark={true}>
        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}>
          <Input
            type="email"
            placeholder="Enter your email address"
            autoFocus
            className={classes.input}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}>
          <Input type="password" placeholder="Enter your password" className={classes.input} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: '100%', marginBottom: 20 }}>
            {isLoading ? 'Loading ...' : 'Sign in'}
          </Button>
        </Form.Item>
        <Row justify="center">
          <Button
            type="link"
            onClick={() => history.push('/forgot-password')}
            className={classes.link}>
            Forgot your password?
          </Button>
        </Row>
      </Form>
    </AuthenticationLayout>
  );
};

const useStyles = makeStyles({
  button: {
    marginRight: spaces.medium,
    marginBottom: spaces.small,
    fontWeight: fontWeight.bold,
  },

  link: {
    color: colors.info,
  },
  input: {
    height: 43,
  },
});

export default Login;
