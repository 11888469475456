import React from 'react';
import { Row, Col } from 'antd';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import logo from '../../../assets/images/logo.png';
import powered from '../../../assets/images/powered-by-prunedge.svg';

import { colors, fontWeight, fontSizes } from '../../../Css';
const AuthenticationLayout = (props) => {
  const classes = useStyles();
  const { title, subTitle, formTitle, children, imgSrc, smallTextTitle } = props;

  return (
    <div className={classes.fullHeightAndWidth}>
      <Row className={classes.fullHeight}>
        <Col xs={0} md={9} className={classes.sideBar}>
          <div>
            <img src={logo} alt="logo" className={classes.logo} />
            <span className={classes.logoText}>Gloepid</span>
          </div>
          <div className={classes.imgContainer}>
            <img src={imgSrc} alt="login" className={classes.img} />
          </div>
        </Col>
        <Col xs={24} md={15} className={classes.formSection}>
          <Row align="middle" className={classes.fullHeight}>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 10, offset: 7 }}>
              <div className={classes.textContainer}>
                <p
                  className={
                    smallTextTitle ? 'displayTextTitle displaySmallTextTitle' : 'displayTextTitle'
                  }>
                  {title}
                </p>
                <p className="displayTextSubtitle">{subTitle}</p>
              </div>
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
      <span className={classes.powered}>
        <img src={powered} alt="powered by prunedge" />
      </span>
    </div>
  );
};

AuthenticationLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles({
  fullHeightAndWidth: {
    width: '100%',
    height: '100vh',
  },
  fullHeight: {
    height: '100vh',
  },
  sideBar: {
    background: colors.white,
    flexDirection: 'column',
    // eslint-disable-next-line
    ['@media (max-width:780px)']: {
      display: 'none',
    },
  },
  avatarContainer: {
    width: 41,
    height: 41,
    background: colors.imageBackground,
    '& img': {
      width: 'inherit',
      height: 'inherit',
    },
  },
  textContainer: {
    '& .displayTextTitle': {
      color: colors.primary,
      fontSize: 36,
      fontWeight: fontWeight.medium,
      margin: '0 0 0 -4px',
    },
    '& .displaySmallTextTitle': {
      fontSize: 35,
    },
    '& .displayTextSubtitle': {
      color: colors.secondaryBase,
      fontSize: fontSizes.medium,
      fontWeight: fontWeight.regular,
      margin: '0 0 15px 0',
    },
  },
  formSection: {
    backgroundColor: colors.white,
    '& .formTitle': {
      marginBottom: 20,
      fontSize: 26,
      margin: 0,
      fontWeight: fontWeight.medium,
      color: colors.base,
    },
  },
  imgContainer: {
    width: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '94vh',
    margin: '0 150.5px',
  },
  img: {},
  logo: {
    position: 'absolute',
    top: 50,
    margin: '0 150.5px',
    width: 30,
    height: 30,
  },
  logoText: {
    position: 'absolute',
    top: 53,
    margin: '0 150.5px 0 190.5px',
    width: 30,
    height: 30,
    fontSize: 14,
    color: '#212B36',
    fontWeight: 600,
  },
  powered: {
    position: 'absolute',
    bottom: '20px',
    left: '45%',
    ['@media (max-width:780px)']: {
      left: '25%',
    },
  },
});

export default React.memo(AuthenticationLayout);
