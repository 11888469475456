import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../routes/PrivateRoute';
import { useAuthenticatedUser } from '../../../hooks/useAuthenticatedUser';
import { Departments, Roles } from '../../../reusables/AccessControl';
import RoleSpecificLoader from '../../../reusables/RoleSpecificLoader';

export const InfoManagementPaths = {
  HOME: '/info-management',
  ASSESSMENT: '/info-management/assessment',
  COMMUNITY: `/info-management/community`,
  CALL_LOG: `/info-management/call-log`,
  SOCIAL_MEDIA: `/info-management/social-media`,
};

const CaseInvestigationRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={InfoManagementPaths.HOME}
        exact={true}
        component={RoleSpecificLoader({
          [Roles.SUPERVISOR]: lazy(() => import('./CaseInvestigation/AssigneeList.supervisor')),
        })}
      />
      <PrivateRoute
        path={`${InfoManagementPaths.HOME}/:id`}
        exact={true}
        component={RoleSpecificLoader({
          [Roles.SUPERVISOR]: lazy(() =>
            import('./CaseInvestigation/AssigneeListDetails.supervisor'),
          ),
        })}
      />
    </Switch>
  );
};

const SurveillanceRoutes = () => {
  return (
    <Switch>
      <PrivateRoute
        path={InfoManagementPaths.ASSESSMENT}
        exact={true}
        component={lazy(() => import('./Surveillance/AssessmentReport'))}
      />
      <PrivateRoute
        path={`${InfoManagementPaths.ASSESSMENT}/:id`}
        exact={true}
        component={RoleSpecificLoader({
          [Roles.SUPERVISOR]: lazy(() =>
            import('./Surveillance/AssessmentReportDetails.supervisor'),
          ),
        })}
      />
      <PrivateRoute
        path={`${InfoManagementPaths.ASSESSMENT}/:id/form`}
        exact={true}
        component={RoleSpecificLoader({
          [Roles.SUPERVISOR]: lazy(() => import('./Surveillance/AssessmentReportForm.supervisor')),
        })}
      />
      <PrivateRoute
        path={InfoManagementPaths.COMMUNITY}
        exact={true}
        component={lazy(() => import('./Surveillance/CommunityReport'))}
      />
      <PrivateRoute
        path={`${InfoManagementPaths.COMMUNITY}/:id`}
        exact={true}
        component={RoleSpecificLoader({
          [Roles.SUPERVISOR]: lazy(() =>
            import('./Surveillance/CommunityReportDetails.supervisor'),
          ),
        })}
      />
      <PrivateRoute
        path={InfoManagementPaths.CALL_LOG}
        exact={true}
        component={lazy(() => import('./Surveillance/CallLog'))}
      />
      <PrivateRoute
        path={`${InfoManagementPaths.CALL_LOG}/:id`}
        exact={true}
        component={RoleSpecificLoader({
          [Roles.SUPERVISOR]: lazy(() => import('./Surveillance/CallLogDetails.supervisor')),
        })}
      />
      <PrivateRoute
        path={InfoManagementPaths.SOCIAL_MEDIA}
        exact={true}
        component={lazy(() => import('./Surveillance/SocialMedia'))}
      />
      <PrivateRoute
        path={`${InfoManagementPaths.SOCIAL_MEDIA}/:id`}
        exact={true}
        component={RoleSpecificLoader({
          [Roles.SUPERVISOR]: lazy(() => import('./Surveillance/SocialMediaDetails.supervisor')),
        })}
      />
    </Switch>
  );
};

const InfoManagementRoutes = () => {
  const { department } = useAuthenticatedUser();

  switch (department) {
    case Departments.SURVEILLANCE:
      return <SurveillanceRoutes />;
    case Departments.CASE_INVESTIGATION:
      return <CaseInvestigationRoutes />;
    default:
      return null;
  }
};

export default React.memo(InfoManagementRoutes);
