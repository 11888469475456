import React from 'react';
import { Layout, Button } from 'antd';

import { MenuOutlined } from '@ant-design/icons';
import { makeStyles } from '@material-ui/styles';
import { colors, fontSizes, spaces } from '../../Css';
import Auth from '../../utils/Auth';
import logo from '../../assets/images/logo.png';
import notification from '../../assets/images/notification.svg';

const { Header } = Layout;

const TopHeader = ({ toggleSidebar }) => {
  const classes = useStyles();
  const user = Auth.getUser();

  return (
    <Header className={classes.container}>
      <Button className={classes.moreBtn} type="link">
        <MenuOutlined className={classes.sidebarToggleBtn} onClick={toggleSidebar} />
        <img src={logo} alt="logo" className={classes.logo} />
        <span className={classes.logoText}>Gloepid</span>
      </Button>
      <div className={classes.userIdentifier}>
        <img src={notification} alt="notification" className={classes.notification} />
        <div className={classes.avatar}></div>
        <span>{user ? `${user.firstname} ${user.lastname}` : 'Helen Birjam'}</span>
      </div>
    </Header>
  );
};

const useStyles = makeStyles({
  container: {
    background: '#F5F5F5',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    lineHeight: 0,
    padding: `0px ${spaces.small}px 0px 0px`,
    color: colors.black,
    marginBottom: 2,
  },
  moreBtn: {
    paddingLeft: spaces.large,
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginLeft: 30,
    width: 30,
    height: 30,
  },
  logoText: {
    fontSize: 14,
    color: '#212B36',
    marginLeft: 10,
    fontWeight: 600,
  },
  notification: {
    marginRight: 30,
  },
  sidebarToggleBtn: {
    color: colors.black,
    fontSize: fontSizes.xxlarge,
  },
  userIdentifier: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: colors.grey,
    marginRight: spaces.small,
  },
});

export default React.memo(TopHeader);
