import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';

import Routes from './routes';
const queryConfig = { queries: { refetchOnWindowFocus: false } }; // This disables background refresh

function App() {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <Routes />
    </ReactQueryConfigProvider>
  );
}

export default React.memo(App);
