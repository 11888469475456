import { Departments, Roles } from './reusables/AccessControl';
import { WorkbenchPaths } from './modules/workbench/pages';
import { InfoManagementPaths } from './modules/infoManagement/pages';
import { CaseManagementPaths } from './modules/caseManagement/pages';
import { OverviewPaths } from './modules/overview/pages';
import { TraceManagementPaths } from './modules/traceManagement/pages';
import { UserAdministrationPaths } from './modules/userAdministration/pages';
import { PartnerAdministrationPaths } from './modules/partnerAdministration/pages';

// Navs represents an object of all the navs used in the app by module id
const Navs = {
  overview: {
    path: OverviewPaths.HOME,
    subNavs: [],
  },
  traceManagement: {
    path: TraceManagementPaths.HOME,
    subNavs: [
      { name: 'Overview', path: TraceManagementPaths.OVERVIEW },
      { name: 'Contact List', path: TraceManagementPaths.HOME },
    ],
  },
  workbench: {
    path: WorkbenchPaths.HOME,
    subNavs: [
      { name: 'Assessment', path: WorkbenchPaths.ASSESSMENT },
      { name: 'Community', path: WorkbenchPaths.COMMUNITY },
      { name: 'Call Logs', path: WorkbenchPaths.CALL_LOG },
      { name: 'Social Media', path: WorkbenchPaths.SOCIAL_MEDIA },
    ],
    subNavsStateCord: [
      { name: 'Overview', path: WorkbenchPaths.OVERVIEW },
      { name: 'Assessment', path: WorkbenchPaths.ASSESSMENT },
      { name: 'Community', path: WorkbenchPaths.COMMUNITY },
      { name: 'Call Logs', path: WorkbenchPaths.CALL_LOG },
      { name: 'Social Media', path: WorkbenchPaths.SOCIAL_MEDIA },
    ],
  },
  infoManagement: {
    path: InfoManagementPaths.HOME,
    subNavs: [
      { name: 'Assessment', path: InfoManagementPaths.ASSESSMENT },
      { name: 'Community', path: InfoManagementPaths.COMMUNITY },
      { name: 'Call Logs', path: InfoManagementPaths.CALL_LOG },
      { name: 'Social Media', path: InfoManagementPaths.SOCIAL_MEDIA },
    ],
  },
  caseManagement: {
    path: CaseManagementPaths.HOME,
    subNavs: [
      { name: 'Overview', path: CaseManagementPaths.OVERVIEW },
      { name: 'Case List', path: CaseManagementPaths.CASE_LIST },
    ],
  },
  userAdministration: {
    path: UserAdministrationPaths.HOME,
    subNavs: [],
  },
  partnerAdministration: {
    path: PartnerAdministrationPaths.HOME,
    subNavs: [],
  },
};

// getSideBarConfigs returns the home path of a module and the subNavs if any
const getSideBarConfigs = (moduleId, hasSubNavs = false, isStateCord = false) => {
  return {
    path: hasSubNavs ? null : Navs[moduleId].path,
    subNavs:
      hasSubNavs === false
        ? null
        : isStateCord
        ? Navs[moduleId].subNavsStateCord
        : Navs[moduleId].subNavs,
  };
};

// removeInfoManagementForCaseOfficer is a filter that removes info mannagement module if the
// user is in the case investigation team and is an officer
const removeInfoManagementForCaseOfficer = (user, modules) => {
  if (user.department === Departments.CASE_INVESTIGATION && user.role === Roles.OFFICER) {
    return modules.filter((module) => module.id !== Modules.INFO_MANAGEMENT.id);
  }
  return modules;
};

// moduleFilters represents an object of filters that can be applied to each module
// You can add custom filters to a module if you want to filter the returned list at runtime
const moduleFilters = {
  [Departments.CASE_INVESTIGATION]: [removeInfoManagementForCaseOfficer],
};

// Modules represents a representation of all the possible modules in the app
export const Modules = {
  WORKBENCH: { id: 'workbench', name: 'Workbench' },
  INFO_MANAGEMENT: { id: 'infoManagement', name: 'Info Management' },
  CASE_MANAGEMENT: { id: 'caseManagement', name: 'Case Management' },
  TRACE_MANAGEMENT: { id: 'traceManagement', name: 'Trace Management' },
  USER_ADMINISTRATION: { id: 'userAdministration', name: 'Users' },
  PARTNER_ADMINISTRATION: { id: 'partnerAdministration', name: 'Partners' },
  OVERVIEW: { id: 'overview', name: 'Overview' },
  SURVEILLANCE_MANAGEMENT: { id: 'workbench', name: 'Surveillance' },
  SURVEILLANCE_LOGGER: { id: 'workbench', name: 'Inbound' },
};

// AccessibleModules returns a list of modules accessible to a user
// user must be of the form {department: DEPARTMENT, officer: OFFICER}
// where [DEPARTMENT] and [OFFICER] are frontend transformed types
export const AccessibleModules = (user) => {
  const departmentalModules = {
    [Departments.SURVEILLANCE]: [
      {
        ...Modules.OVERVIEW,
        sideBarConfigs: getSideBarConfigs(Modules.OVERVIEW.id),
      },
      {
        ...Modules.WORKBENCH,
        sideBarConfigs: getSideBarConfigs(Modules.WORKBENCH.id, true),
      },
      {
        ...Modules.INFO_MANAGEMENT,
        sideBarConfigs: getSideBarConfigs(Modules.INFO_MANAGEMENT.id, true),
      },
    ],
    [Departments.CALL_CENTER_LOGGER]: [
      {
        ...Modules.OVERVIEW,
        sideBarConfigs: getSideBarConfigs(Modules.OVERVIEW.id),
      },
      {
        ...Modules.SURVEILLANCE_LOGGER,
        sideBarConfigs: getSideBarConfigs(Modules.WORKBENCH.id),
      },
    ],
    [Departments.CASE_INVESTIGATION]: [
      {
        ...Modules.OVERVIEW,
        sideBarConfigs: getSideBarConfigs(Modules.OVERVIEW.id),
      },
      {
        ...Modules.CASE_MANAGEMENT,
        sideBarConfigs: getSideBarConfigs(Modules.CASE_MANAGEMENT.id),
      },
      {
        ...Modules.WORKBENCH,
        sideBarConfigs: getSideBarConfigs(Modules.WORKBENCH.id),
      },
      // {
      //   ...Modules.INFO_MANAGEMENT,
      //   sideBarConfigs: getSideBarConfigs(Modules.INFO_MANAGEMENT.id),
      // },
    ],
    [Departments.STATE_COORDINATOR]: [
      {
        ...Modules.SURVEILLANCE_MANAGEMENT,
        sideBarConfigs: getSideBarConfigs(Modules.SURVEILLANCE_MANAGEMENT.id, true, true),
      },
      {
        ...Modules.CASE_MANAGEMENT,
        sideBarConfigs: getSideBarConfigs(Modules.CASE_MANAGEMENT.id, true),
      },
      {
        ...Modules.TRACE_MANAGEMENT,
        sideBarConfigs: getSideBarConfigs(Modules.TRACE_MANAGEMENT.id, true),
      },
    ],
    [Departments.LAB]: [
      {
        ...Modules.CASE_MANAGEMENT,
        sideBarConfigs: getSideBarConfigs(Modules.CASE_MANAGEMENT.id),
      },
      {
        ...Modules.WORKBENCH,
        sideBarConfigs: getSideBarConfigs(Modules.WORKBENCH.id),
      },
    ],
    [Departments.CONTACT_TRACE]: [
      {
        ...Modules.OVERVIEW,
        sideBarConfigs: getSideBarConfigs(Modules.OVERVIEW.id),
      },
      {
        ...Modules.TRACE_MANAGEMENT,
        sideBarConfigs: getSideBarConfigs(Modules.TRACE_MANAGEMENT.id),
      },
      {
        ...Modules.WORKBENCH,
        sideBarConfigs: getSideBarConfigs(Modules.WORKBENCH.id),
      },
    ],
    [Departments.ADMINISTRATOR]: [
      {
        ...Modules.USER_ADMINISTRATION,
        sideBarConfigs: getSideBarConfigs(Modules.USER_ADMINISTRATION.id),
      },
      {
        ...Modules.PARTNER_ADMINISTRATION,
        sideBarConfigs: getSideBarConfigs(Modules.PARTNER_ADMINISTRATION.id),
      },
    ],
  };

  // checks if there are module filters and applies them via [piping]
  // the returned array is the filtered list
  // it returns the modules accessible to an entire department if there are no filters
  // [N:B] - It might be best to return null i.e to intentionally crash the application if the user does not exist
  // so we can handle this properly with a fallback UI
  return (
    moduleFilters[user.department]?.reduce((acc, filter) => {
      acc = filter(user, acc);
      return acc;
    }, departmentalModules[user.department]) ||
    departmentalModules[user.department] ||
    []
  );
};

// getDefaultPath returns the default path a departmental user will see when dropped on the dashboard
export const getDefaultPath = ({ department, role }) => {
  if (department || role) {
    const sideBarConfigs = AccessibleModules({ department, role })[0]?.sideBarConfigs || {};
    return sideBarConfigs?.path || sideBarConfigs?.subNavs[0]?.path;
  }
  return null;
};
